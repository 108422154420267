/*	================================================================================
 *	NORMALIZE
 *	================================================================================ */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none; }

html {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif; }

body {
  min-height: 100%;
  line-height: 1; }

*:before, *:after {
  box-sizing: inherit; }

article, aside, details, figure, figcaption, header, footer, hgroup, menu, nav, section, summary, hr, .is-block {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

img {
  border: none; }

svg:not(:root) {
  overflow: hidden; }

/* Correct overflow not hidden in IE 9/10/11. */
/*	================================================================================
 *	BLOCK AND SEMI-BLOCK ELEMENTS
 *	================================================================================ */
hr {
  height: 1px;
  border-style: solid;
  border-width: 1px 0 0 0; }

.hero {
  width: 100%;
  height: 100%;
  height: 100vh; }

.clear:after {
  content: '';
  display: block;
  clear: both; }

/*	================================================================================
 *	COMMON CLASSES
 *	================================================================================ */
/*	----------------------------------------------------------------------------
 *	ALIGNMENTS
 */
.align-left {
  text-align: left !important; }

.align-center {
  text-align: center !important; }

.align-right {
  text-align: right !important; }

.align-justify {
  text-align: justify !important; }

.valign-top {
  vertical-align: top; }

.valign-middle {
  vertical-align: middle; }

.valign-bottom {
  vertical-align: bottom; }

.box-center {
  margin-left: auto;
  margin-right: auto; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.z-plus {
  z-index: +1; }

.z-minus {
  z-index: -1; }

/*	----------------------------------------------------------------------------
 *	DISPLAY
 */
.is-hidden {
  visibility: hidden; }

.is-disabled {
  pointer-events: none; }

.is-not-displayed {
  display: none; }

.is-inline {
  display: inline; }

.is-table {
  display: table; }

.is-table-row {
  display: table-row; }

.is-table-row > *,
.is-table-cell {
  display: table-cell;
  vertical-align: middle; }

.is-relative {
  position: relative; }

.is-absolute {
  position: absolute; }

.is-fixed {
  position: fixed; }

.is-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/*	----------------------------------------------------------------------------
 *	SIZES
 */
.width-full {
  width: 100%; }

.width-50 {
  width: 50%; }

.height-full {
  height: 100%; }

.height-50 {
  height: 50%; }

/*	----------------------------------------------------------------------------
 *	TRANSFORMATIONS
 */
.rotate-90 {
  transform: rotate(90deg); }

.rotate-180 {
  transform: rotate(180deg); }

.rotate-270 {
  transform: rotate(270deg); }

/*	----------------------------------------------------------------------------
 *	OTHER
 */
.is-clickable {
  cursor: pointer; }

.overflow-hidden {
  overflow: hidden; }

.overflow-scroll {
  overflow: scroll; }

/*	================================================================================
 *	GRIDS
 *	================================================================================ */
/*	----------------------------------------------------------------------------
 *	FLOATING GRID
 */
.grid {
  position: relative; }

.grid > [class*='col-'] {
  float: left;
  min-height: 1px; }

.grid:after {
  content: '';
  display: block;
  clear: both; }

.grid > .col-1 {
  width: 8.33%;
  width: calc(100% / 12); }

.grid > .col-2 {
  width: 16.66%;
  width: calc(100% / 6); }

.grid > .col-3 {
  width: 25%; }

.grid > .col-4 {
  width: 33.33%;
  width: calc(100% / 3); }

.grid > .col-5 {
  width: 41.66%;
  width: calc(100% / 12 * 5); }

.grid > .col-6 {
  width: 50%; }

.grid > .col-7 {
  width: 58.33%;
  width: calc(100% / 12 * 7); }

.grid > .col-8 {
  width: 66.66%;
  width: calc(100% / 12 * 8); }

.grid > .col-9 {
  width: 75%; }

.grid > .col-10 {
  width: 83.33%;
  width: calc(100% / 12 * 10); }

.grid > .col-11 {
  width: 91.66%;
  width: calc(100% / 12 * 11); }

/*	----------------------------------------------------------------------------
 *	FLEX GRID
 */
.flex-grid {
  display: flex; }

.flex-grid > * {
  display: flexbox;
  flex: 1; }

/*	================================================================================
 *	TYPOGRAPHY, LISTS, LINKS
 *	================================================================================ */
p:not(:last-child) {
  margin-bottom: 1em; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
  cursor: help; }

strong, .is-bold {
  font-weight: bold; }

u, .is-underlined {
  text-decoration: underline; }

sub, sup {
  font-size: .8em;
  line-height: 0;
  vertical-align: baseline;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

a {
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  /* Remove the gray background color from active links in IE 10. */ }

/*	================================================================================
 *	TABLES
 *	================================================================================ */
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

th, td {
  border-style: solid;
  border-width: 0;
  vertical-align: middle;
  text-align: left; }

.all-lines td,
.all-lines th {
  border-width: 1px; }

.horizontal-lines th,
.horizontal-lines td {
  border-bottom-width: 1px; }

/*	================================================================================
 *	FORMS
 *	================================================================================ */
input[type="radio"],
input[type="checkbox"] {
  position: relative;
  top: -1px;
  vertical-align: middle; }

button,
.btn,
label,
input[type=button],
input[type=submit],
input[type=file] {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer; }

button:hover,
.btn:hover,
label:hover,
input[type=button]:hover,
input[type=submit]:hover,
input[type=file]:hover {
  z-index: +1; }

[disabled] {
  cursor: default; }

/*	================================================================================
 *	ANIMATIONS
 *	================================================================================ */
.animation-spin {
  animation: spin-around 1s infinite linear; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.shake {
  animation: shake 2s infinite linear; }

@keyframes shake {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }
