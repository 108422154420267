


/*	================================================================================
 *	NORMALIZE
 *	================================================================================ */


* {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-size: 100%;
	outline: none;
}

html {
	height: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: sans-serif;
}

body {
	min-height: 100%;
	line-height: 1;
}

*:before, *:after { box-sizing: inherit; }

article, aside, details, figure, figcaption, header, footer, hgroup, menu, nav, section, summary, hr, .is-block { display: block; }

audio, canvas, progress, video {
	display: inline-block;
	vertical-align: baseline;
}

img { border: none; }

svg:not(:root) { overflow: hidden; } /* Correct overflow not hidden in IE 9/10/11. */