


/*	================================================================================
 *	GRIDS
 *	================================================================================ */


/*	----------------------------------------------------------------------------
 *	FLOATING GRID
 */


.grid { position: relative; }

	.grid > [class*='col-'] {
		float: left;
		min-height: 1px;
	}

	.grid:after {
		content: '';
		display: block;
		clear: both;
	}

	.grid > .col-1  { width: 8.33%;  width: calc(100% / 12); }
	.grid > .col-2  { width: 16.66%; width: calc(100% / 6); }
	.grid > .col-3  { width: 25%; }
	.grid > .col-4  { width: 33.33%; width: calc(100% / 3); }
	.grid > .col-5  { width: 41.66%; width: calc(100% / 12 * 5); }
	.grid > .col-6  { width: 50%; }
	.grid > .col-7  { width: 58.33%; width: calc(100% / 12 * 7); }
	.grid > .col-8  { width: 66.66%; width: calc(100% / 12 * 8); }
	.grid > .col-9  { width: 75%; }
	.grid > .col-10 { width: 83.33%; width: calc(100% / 12 * 10); }
	.grid > .col-11 { width: 91.66%; width: calc(100% / 12 * 11); }


/*	----------------------------------------------------------------------------
 *	FLEX GRID
 */


.flex-grid { display: flex; }

	.flex-grid > * {
		display: flexbox;
		flex: 1;
	}