


/*	================================================================================
 *	BLOCK AND SEMI-BLOCK ELEMENTS
 *	================================================================================ */


hr {
	height: 1px;
	border-style: solid;
	border-width: 1px 0 0 0;
}

.hero {
	width: 100%;
	height: 100%;
	height: 100vh;
}

.clear:after {
	content: '';
	display: block;
	clear: both;
}



/*	================================================================================
 *	COMMON CLASSES
 *	================================================================================ */


/*	----------------------------------------------------------------------------
 *	ALIGNMENTS
 */

.align-left			{ text-align: left !important; }
.align-center		{ text-align: center !important; }
.align-right		{ text-align: right !important; }
.align-justify		{ text-align: justify !important; }

.valign-top			{ vertical-align: top; }
.valign-middle		{ vertical-align: middle; }
.valign-bottom		{ vertical-align: bottom; }

.box-center {
	margin-left: auto;
	margin-right: auto;
}

.float-left			{ float: left; }
.float-right		{ float: right; }

.z-plus				{ z-index: +1; }
.z-minus			{ z-index: -1; }


/*	----------------------------------------------------------------------------
 *	DISPLAY
 */

.is-hidden			{ visibility: hidden; }

.is-disabled		{ pointer-events: none; }

.is-not-displayed	{ display: none; }
.is-inline			{ display: inline; }
.is-table			{ display: table; }

	.is-table-row 	{ display: table-row; }

		.is-table-row > *,
		.is-table-cell {
			display: table-cell;
			vertical-align: middle;
		}

.is-relative		{ position: relative; }
.is-absolute		{ position: absolute; }
.is-fixed			{ position: fixed; }

.is-unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}


/*	----------------------------------------------------------------------------
 *	SIZES
 */

.width-full			{ width: 100%; }
.width-50			{ width: 50%; }
.height-full		{ height: 100%; }
.height-50			{ height: 50%; }


/*	----------------------------------------------------------------------------
 *	TRANSFORMATIONS
 */

.rotate-90			{ transform: rotate(90deg); }
.rotate-180			{ transform: rotate(180deg); }
.rotate-270			{ transform: rotate(270deg); }



/*	----------------------------------------------------------------------------
 *	OTHER
 */

.is-clickable		{ cursor: pointer; }

.overflow-hidden	{ overflow: hidden; }
.overflow-scroll	{ overflow: scroll; }