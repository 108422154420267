/*	================================================================================
 *	FORMS
 *	================================================================================ */


input[type="radio"],
input[type="checkbox"] {
	position: relative;
	top: -1px;
	vertical-align: middle;
}

button,
.btn,
label,
input[type=button],
input[type=submit],
input[type=file] {
	-webkit-appearance: none;
	-moz-appearance: none;
	cursor: pointer;
}

button:hover,
.btn:hover,
label:hover,
input[type=button]:hover,
input[type=submit]:hover,
input[type=file]:hover {
	z-index: +1;
}

[disabled] { cursor: default; }