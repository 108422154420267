/*	================================================================================
 *	ANIMATIONS
 *	================================================================================ */


.animation-spin { animation: spin-around 1s infinite linear; }

@keyframes spin {
	from 	{ transform: rotate(0deg); }
	to 		{ transform: rotate(359deg); }
}

.shake { animation: shake 2s infinite linear; }

@keyframes shake {
	0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { transform: translateX(7%); }
	5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { transform: translateX(-7%); }
}