


/*	================================================================================
 *	TYPOGRAPHY, LISTS, LINKS
 *	================================================================================ */


p:not(:last-child) { margin-bottom: 1em; }

del { text-decoration: line-through; }

abbr[title], dfn[title] {
	border-bottom: 1px dotted rgba(0,0,0,.5);
	cursor: help;
}

strong, .is-bold	{ font-weight: bold; }
u, .is-underlined	{ text-decoration: underline; }

sub, sup {
	font-size: .8em;
	line-height: 0;
	vertical-align: baseline;
	position: relative;
}
sup { top: -0.5em; }
sub { bottom: -0.25em; }

a {
	text-decoration: none;
	cursor: pointer;
	background-color: transparent; /* Remove the gray background color from active links in IE 10. */
}