


/*	================================================================================
 *	TABLES
 *	================================================================================ */


table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

th, td {
	border-style: solid;
	border-width: 0;
	vertical-align: middle;
	text-align: left;
}

.all-lines td,
.all-lines th {
	border-width: 1px;
}

.horizontal-lines th,
.horizontal-lines td {
	border-bottom-width: 1px;
}